<template>
    <div>
        <test></test>
        <div class="imgCont">
            <img src="./images/jyxkz.png"/>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import test from './components/test.vue'
    import foot from './components/foot.vue'

    export default {
        components: {
            test,
            foot
        },
        data() {
            return {}
        },
        created() {
        }
    }
</script>

<style>
    body {
        background-color: #ffffff;
    }

    .imgCont {
        margin-top: 70px;
        width: 100%;
        min-height: 500px;
    }
    .imgCont img{
        width:800px;
    }
</style>
